import React, { Component } from "react";
import { navigate } from "gatsby";

export default class _error extends Component {
  componentDidMount = () => {
    navigate("/");
  };

  render() {
    return <div />;
  }
}
